.vis-custom-time {
  background-color: #6E94FF;
  width: 2px;
  cursor: move;
  z-index: 1;
}

.vis-custom-time > .vis-custom-time-marker {
  background-color: inherit;
  color: white;
  font-size: 12px;
  white-space: nowrap;
  padding: 3px 5px;
  top: 0px;
  cursor: initial;
  z-index: inherit;
}