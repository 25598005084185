.vis-graph-group0 {
    fill:#4f81bd;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #4f81bd;
}

.vis-graph-group1 {
    fill:#f79646;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #f79646;
}

.vis-graph-group2 {
    fill: #8c51cf;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #8c51cf;
}

.vis-graph-group3 {
    fill: #75c841;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #75c841;
}

.vis-graph-group4 {
    fill: #ff0100;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #ff0100;
}

.vis-graph-group5 {
    fill: #37d8e6;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #37d8e6;
}

.vis-graph-group6 {
    fill: #042662;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #042662;
}

.vis-graph-group7 {
    fill:#00ff26;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #00ff26;
}

.vis-graph-group8 {
    fill:#ff00ff;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #ff00ff;
}

.vis-graph-group9 {
    fill: #8f3938;
    fill-opacity:0;
    stroke-width:2px;
    stroke: #8f3938;
}

.vis-timeline .vis-fill {
    fill-opacity:0.1;
    stroke: none;
}


.vis-timeline .vis-bar {
    fill-opacity:0.5;
    stroke-width:1px;
}

.vis-timeline .vis-point {
    stroke-width:2px;
    fill-opacity:1.0;
}


.vis-timeline .vis-legend-background {
    stroke-width:1px;
    fill-opacity:0.9;
    fill: #ffffff;
    stroke: #c2c2c2;
}


.vis-timeline .vis-outline {
    stroke-width:1px;
    fill-opacity:1;
    fill: #ffffff;
    stroke: #e5e5e5;
}

.vis-timeline .vis-icon-fill {
    fill-opacity:0.3;
    stroke: none;
}
