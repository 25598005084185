.vis-panel {
  position: absolute;

  padding: 0;
  margin: 0;

  box-sizing: border-box;
}

.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border: 1px #bfbfbf;
}

.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right {
  border-top-style: solid;
  border-bottom-style: solid;
  overflow: hidden;
}

.vis-left.vis-panel.vis-vertical-scroll, .vis-right.vis-panel.vis-vertical-scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
} 

.vis-left.vis-panel.vis-vertical-scroll {
  direction: rtl;
}

.vis-left.vis-panel.vis-vertical-scroll .vis-content {
  direction: ltr;
}

.vis-right.vis-panel.vis-vertical-scroll {
  direction: ltr;
}

.vis-right.vis-panel.vis-vertical-scroll .vis-content {
  direction: rtl;
}

.vis-panel.vis-center,
.vis-panel.vis-top,
.vis-panel.vis-bottom {
  border-left-style: solid;
  border-right-style: solid;
}

.vis-background {
  overflow: hidden;
}

.vis-panel > .vis-content {
  position: relative;
}

.vis-panel .vis-shadow {
  position: absolute;
  width: 100%;
  height: 1px;
  box-shadow: 0 0 10px rgba(0,0,0,0.8);
  /* TODO: find a nice way to ensure vis-shadows are drawn on top of items
  z-index: 1;
  */
}

.vis-panel .vis-shadow.vis-top {
  top: -1px;
  left: 0;
}

.vis-panel .vis-shadow.vis-bottom {
  bottom: -1px;
  left: 0;
}