
.vis-timeline {
  position: relative;
  border: 1px solid #bfbfbf;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.vis-loading-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}