div.vis-configuration {
    position:relative;
    display:block;
    float:left;
    font-size:12px;
}

div.vis-configuration-wrapper {
    display:block;
    width:700px;
}

div.vis-configuration-wrapper::after {
  clear: both;
  content: "";
  display: block;
}

div.vis-configuration.vis-config-option-container{
    display:block;
    width:495px;
    background-color: #ffffff;
    border:2px solid #f7f8fa;
    border-radius:4px;
    margin-top:20px;
    left:10px;
    padding-left:5px;
}

div.vis-configuration.vis-config-button{
    display:block;
    width:495px;
    height:25px;
    vertical-align: middle;
    line-height:25px;
    background-color: #f7f8fa;
    border:2px solid #ceced0;
    border-radius:4px;
    margin-top:20px;
    left:10px;
    padding-left:5px;
    cursor: pointer;
    margin-bottom:30px;
}

div.vis-configuration.vis-config-button.hover{
    background-color: #4588e6;
    border:2px solid #214373;
    color:#ffffff;
}

div.vis-configuration.vis-config-item{
    display:block;
    float:left;
    width:495px;
    height:25px;
    vertical-align: middle;
    line-height:25px;
}


div.vis-configuration.vis-config-item.vis-config-s2{
    left:10px;
    background-color: #f7f8fa;
    padding-left:5px;
    border-radius:3px;
}
div.vis-configuration.vis-config-item.vis-config-s3{
    left:20px;
    background-color: #e4e9f0;
    padding-left:5px;
    border-radius:3px;
}
div.vis-configuration.vis-config-item.vis-config-s4{
    left:30px;
    background-color: #cfd8e6;
    padding-left:5px;
    border-radius:3px;
}

div.vis-configuration.vis-config-header{
    font-size:18px;
    font-weight: bold;
}

div.vis-configuration.vis-config-label{
    width:120px;
    height:25px;
    line-height: 25px;
}

div.vis-configuration.vis-config-label.vis-config-s3{
    width:110px;
}
div.vis-configuration.vis-config-label.vis-config-s4{
    width:100px;
}

div.vis-configuration.vis-config-colorBlock{
    top:1px;
    width:30px;
    height:19px;
    border:1px solid #444444;
    border-radius:2px;
    padding:0px;
    margin:0px;
    cursor:pointer;
}

input.vis-configuration.vis-config-checkbox {
    left:-5px;
}


input.vis-configuration.vis-config-rangeinput{
    position:relative;
    top:-5px;
    width:60px;
    /*height:13px;*/
    padding:1px;
    margin:0;
    pointer-events:none;
}

input.vis-configuration.vis-config-range{
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 0px solid white;
    background-color:rgba(0,0,0,0);

    /*required for proper track sizing in FF*/
    width: 300px;
    height:20px;
}
input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #dedede; /* Old browsers */
    background: -moz-linear-gradient(top,  #dedede 0%, #c8c8c8 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dedede), color-stop(99%,#c8c8c8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* IE10+ */
    background: linear-gradient(to bottom,  #dedede 0%,#c8c8c8 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */

    border: 1px solid #999999;
    box-shadow: #aaaaaa 0px 0px 3px 0px;
    border-radius: 3px;
}
input.vis-configuration.vis-config-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #14334b;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: #3876c2; /* Old browsers */
    background: -moz-linear-gradient(top,  #3876c2 0%, #385380 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3876c2), color-stop(100%,#385380)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #3876c2 0%,#385380 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #3876c2 0%,#385380 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #3876c2 0%,#385380 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #3876c2 0%,#385380 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3876c2', endColorstr='#385380',GradientType=0 ); /* IE6-9 */
    box-shadow: #111927 0px 0px 1px 0px;
    margin-top: -7px;
}
input.vis-configuration.vis-config-range:focus {
    outline: none;
}
input.vis-configuration.vis-config-range:focus::-webkit-slider-runnable-track {
    background: #9d9d9d; /* Old browsers */
    background: -moz-linear-gradient(top, #9d9d9d 0%, #c8c8c8 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#9d9d9d), color-stop(99%,#c8c8c8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #9d9d9d 0%,#c8c8c8 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #9d9d9d 0%,#c8c8c8 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #9d9d9d 0%,#c8c8c8 99%); /* IE10+ */
    background: linear-gradient(to bottom,  #9d9d9d 0%,#c8c8c8 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9d9d9d', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */
}

input.vis-configuration.vis-config-range::-moz-range-track {
    width: 300px;
    height: 10px;
    background: #dedede; /* Old browsers */
    background: -moz-linear-gradient(top,  #dedede 0%, #c8c8c8 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dedede), color-stop(99%,#c8c8c8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #dedede 0%, #c8c8c8 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dedede 0%,#c8c8c8 99%); /* IE10+ */
    background: linear-gradient(to bottom,  #dedede 0%,#c8c8c8 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#c8c8c8',GradientType=0 ); /* IE6-9 */

    border: 1px solid #999999;
    box-shadow: #aaaaaa 0px 0px 3px 0px;
    border-radius: 3px;
}
input.vis-configuration.vis-config-range::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;

    border-radius: 50%;
    background:  #385380;
}

/*hide the outline behind the border*/
input.vis-configuration.vis-config-range:-moz-focusring{
    outline: 1px solid white;
    outline-offset: -1px;
}

input.vis-configuration.vis-config-range::-ms-track {
    width: 300px;
    height: 5px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input.vis-configuration.vis-config-range::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
}
input.vis-configuration.vis-config-range::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input.vis-configuration.vis-config-range::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background:  #385380;
}
input.vis-configuration.vis-config-range:focus::-ms-fill-lower {
    background: #888;
}
input.vis-configuration.vis-config-range:focus::-ms-fill-upper {
    background: #ccc;
}

.vis-configuration-popup {
    position: absolute;
    background: rgba(57, 76, 89, 0.85);
    border: 2px solid #f2faff;
    line-height:30px;
    height:30px;
    width:150px;
    text-align:center;
    color: #ffffff;
    font-size:14px;
    border-radius:4px;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.vis-configuration-popup:after, .vis-configuration-popup:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.vis-configuration-popup:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: rgba(57, 76, 89, 0.85);
    border-width: 8px;
    margin-top: -8px;
}
.vis-configuration-popup:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #f2faff;
    border-width: 12px;
    margin-top: -12px;
}