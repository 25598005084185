
.vis-panel.vis-background.vis-horizontal .vis-grid.vis-horizontal {
  position: absolute;
  width: 100%;
  height: 0;
  border-bottom: 1px solid;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-minor {
  border-color: #e5e5e5;
}

.vis-panel.vis-background.vis-horizontal .vis-grid.vis-major {
  border-color: #bfbfbf;
}


.vis-data-axis .vis-y-axis.vis-major {
  width: 100%;
  position: absolute;
  color: #4d4d4d;
  white-space: nowrap;
}

.vis-data-axis .vis-y-axis.vis-major.vis-measure {
  padding: 0;
  margin: 0;
  border: 0;
  visibility: hidden;
  width: auto;
}


.vis-data-axis .vis-y-axis.vis-minor {
  position: absolute;
  width: 100%;
  color: #bebebe;
  white-space: nowrap;
}

.vis-data-axis .vis-y-axis.vis-minor.vis-measure {
  padding: 0;
  margin: 0;
  border: 0;
  visibility: hidden;
  width: auto;
}

.vis-data-axis .vis-y-axis.vis-title {
  position: absolute;
  color: #4d4d4d;
  white-space: nowrap;
  bottom: 20px;
  text-align: center;
}

.vis-data-axis .vis-y-axis.vis-title.vis-measure {
  padding: 0;
  margin: 0;
  visibility: hidden;
  width: auto;
}

.vis-data-axis .vis-y-axis.vis-title.vis-left {
  bottom: 0;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left bottom;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.vis-data-axis .vis-y-axis.vis-title.vis-right {
  bottom: 0;
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.vis-legend {
  background-color: rgba(247, 252, 255, 0.65);
  padding: 5px;
  border: 1px solid #b3b3b3;
  box-shadow: 2px 2px 10px rgba(154, 154, 154, 0.55);
}

.vis-legend-text {
  /*font-size: 10px;*/
  white-space: nowrap;
  display: inline-block
}